<template>
  <div class="comment">
    <el-tabs v-model="activeName" class="el-nav">
      <el-tab-pane :label="'直播弹幕'" name="live">
        <div class="content-b-list">
          <div class="" style="width: 100%; line-height: 1" v-for="(item, index) in commentList" :key="index">
            <div class="content-b-list-item" v-if="item.nick && item.text">
              <span>{{ item.nick }}:</span>
              {{ item.text }}
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
export default {
  props: {},
  data() {
    return {
      activeName: 'live',
      tim: '',
      video_uuid: '',
      commentList: [],
    };
  },
  created() {
    this.video_uuid = this.$route.params.uuid;
  },
  mounted() {
    this.imInit();
  },
  methods: {
    imInit() {
      // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
      this.tim = TIM.create({ SDKAppID: process.env.VUE_APP_SDKApp_ID }); // SDK 实例通常用 tim 表示

      // 设置 SDK 日志输出级别，详细分级请参见 setLogLevel https://web.sdk.qcloud.com/im/doc/zh-cn/SDK.html#setLogLevel 接口的说明</a>
      // this.tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
      this.tim.setLogLevel(process.env.VUE_APP_RELEASE); // release 级别，SDK 输出关键信息，生产环境时建议使用

      // 注册腾讯云即时通信 IM 上传插件
      this.tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });

      this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onMessageReceived);
      this.tim.joinGroup({ groupID: this.video_uuid });
    },

    onMessageReceived(event) {
      this.commentList = this.commentList.concat({ nick: event.data[0].nick, text: event.data[0].payload.text });
      setTimeout(() => {
        let ele = document.getElementsByClassName('content-b-list')[0];
        ele.scrollTop = ele.scrollHeight;
      }, 500);
    },
  },
  components: {},
};
</script>
<style lang="less" scoped>
.comment {
  width: 95%;
  height: 95%;
  background: #ffffff;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  /deep/.el-tabs__content {
    padding-top: 15px;
    height: 680px;
  }
}
.upload-demo {
  width: 100%;
  /deep/ .el-upload {
    width: 100%;
  }
  /deep/ .el-upload-dragger {
    width: 100%;
  }
}
.content-b-list {
  overflow: auto;
  padding: 0 20px;
  height: 100%;
  .content-b-list-item {
    display: inline-block;
    max-width: 100%;
    word-break: break-all;
    background: #f6f8f9;
    padding: 7px 10px;
    border-radius: 12px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #71777d;
    line-height: 30px;
    margin-bottom: 10px;
    box-sizing: border-box;
    span {
      color: #1c1f21;
    }
  }
}
//标签页激活
/deep/ .el-tabs__item:hover {
  color: #71777d;
  cursor: pointer;
}
.el-tab-pane {
  height: 100%;
}
/deep/.el-tabs__header {
  padding: 0 20px;
}
/deep/ .el-tabs__item.is-active {
  color: @theme;
  font-weight: bold;
}
/deep/ .el-input__inner {
  height: 36px;
  .el-range__icon {
    line-height: 29px;
  }
  .el-range-separator {
    line-height: 28px;
  }
}

/deep/ .el-tabs__item {
  padding: 25px 40px 23px 0;
  height: 64px;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #93999f;
}
</style>
